var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" Pendaftaran Organisasi " + _vm._s(_vm.organizationType) + " Baru ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('h4', {
    staticClass: "title text-center"
  }, [_vm._v(" MAKLUMAT ORGANISASI " + _vm._s(_vm.organizationType) + " BARU ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('img', {
    staticClass: "avatar avatar-large rounded-circle shadow d-block mx-auto",
    attrs: {
      "src": "images/client/05.jpg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_c('a', {
    staticClass: "btn btn-primary mt-2",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(_vm._s(_vm.$t("change-picture")))]), _c('a', {
    staticClass: "btn btn-outline-primary mt-2 ml-2",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(_vm._s(_vm.$t("reset")))])])])])])]), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("shortname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("code")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Alamat",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Poskod",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Bandar",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Negeri",
      "name": "",
      "required": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("tel-no")))]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Telefon",
      "name": "",
      "required": ""
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("user-status")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])])])])])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative text-right"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": "/page-blog-grid"
    }
  }, [_vm._v(_vm._s(_vm.$t("save")))])], 1)])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": ""
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Wasiat")])])])]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": ""
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Hibah")])])])])]);
}]

export { render, staticRenderFns }